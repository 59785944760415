<template>
  <el-form ref="updateDetails" :label-position="labelPosition" label-width="100px" :model="profile" class="mt-4" :rules="rules">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :md="18">
        <el-form-item label="First Name" prop="first_name">
          <el-input v-model="profile.first_name" maxlength="255" show-word-limit />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="24" :md="18">
        <el-form-item label="Last Name" prop="last_name">
          <el-input v-model="profile.last_name" maxlength="255" show-word-limit />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-for="(email,i) in emails" :key="i" type="flex" justify="center">
      <el-col :xs="24" :md="18">
        <el-form-item :label="'Email'+' '+(i+1)" prop="email">
          <!-- <el-input v-model="profile.email"
                    :disabled="email.primary"
          > -->
          <el-input v-model="profile.email" type="email">
            <el-button v-if="emails.length > 1 && ! hasPrimary" slot="append" @click="setAsPrimary(i)">Set as primary email</el-button>
            <el-button v-if="emails.length > 1 && ! email.primary" slot="append" @click="removeEmailField(i)">X</el-button>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="text-right buttons pb-4" style="position: relative;">
      <error-alert :errors="Profile.errors" class="profile-alert" />
      <el-button type="success" @click="save" :disabled="Profile.saving" :loading="Profile.saving">
        Save
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { maxLength, required } from '~/rules'
import ErrorAlert from '~/components/ErrorAlert'
import MyFreelancerProfile from '~/models/MyFreelancerProfile'

export default {
  components: { ErrorAlert },

  data () {
    return {
      emails: [],
      ctr: 1,
      labelPosition: 'left',
      hasPrimary: false,
      Profile: new MyFreelancerProfile('name-and-email'),
      rules: {
        first_name: [required('First Name'), maxLength('First Name')],
        last_name: [required('Last Name'), maxLength('Last Name')],
        email: [required('Email'), maxLength('Email')],
      },
    }
  },

  computed: {
    profile () {
      return this.Profile.data
    },
  },

  mounted () {
    this.emails.push({
      id: this.ctr,
      label: 'test@gmail.com',
      primary: false,
    })
  },

  methods: {
    removeEmailField (i) {
      this.ctr--
      this.emails.splice(i, 1)
      if (this.ctr === 1) {
        this.hasPrimary = false
      }
    },

    addNewEmailField () {
      if (this.ctr === 4) return
      this.ctr++
      this.emails.push({ id: this.ctr, label: '', primary: false })
    },

    setAsPrimary (i) {
      this.hasPrimary = true
      this.emails[i].primary = true
    },

    save () {
      this.Profile.save().then(() => this.$store.dispatch('freelancerGet'))
    },
  },
}
</script>

<style lang="css" scoped>
.el-icon-view {
    color: #409EFF;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 12px;
    top: 12px;
}
.profile-alert {
    text-align: left;
    max-width: 400px;
    width: 100%;
    margin: 0 auto 25px;
    /*margin: 3% 3% 1% 25px;*/
    font-weight: bold;

}
.profile-alert ul{
    margin-bottom: 5px;
}
.profile-alert ul li {
    list-style: none;
}
.profile-alert ul{
    margin-bottom: 5px;
}
</style>
