<template>
  <div class="detail-form bio">
    <p class="mb-3">Update your profile</p>
    <bio-and-pricing-form :profile="Profile.data" />
    <div class="text-right buttons pb-4" style="position: relative;">
      <bio-and-pricing-errors :errors="Profile.errors" class="profile-alert" />
      <el-button
        :loading="Profile.saving"
        :disabled="Profile.saving"
        type="success"
        @click="save"
      >
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
import BioAndPricingForm from '~/components/Freelancer/BioAndPricing/Form.vue'
import BioAndPricingErrors from '~/components/Freelancer/BioAndPricing/Errors.vue'
import MyFreelancerProfile from '~/models/MyFreelancerProfile'

export default {
  name: 'TabBio',
  components: {
    BioAndPricingForm,
    BioAndPricingErrors,
  },

  data () {
    return {
      Profile: new MyFreelancerProfile('bio'),
    }
  },

  computed: {
    errors () {
      return this.Profile.errors
    },
  },

  methods: {
    save () {
      this.Profile.saveBio()
        .then(r => {
          this.$store.commit('FREELANCER_UPDATE_PROFILE', r.data)
        })
    }
  }
}
</script>
  