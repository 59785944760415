<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :xs="24" :md="24">
        <div id="freelancer" class="accountdetails">
          <el-tabs v-model="tab" type="border-card">
            <el-tab-pane label="Profile">
              <tab-bio />
            </el-tab-pane>
            <el-tab-pane label="Address">
              <tab-address />
            </el-tab-pane>
            <el-tab-pane label="Name and Email">
              <div class="detail-form">
                <p class="mb-4">Update account details</p>
                <el-row>
                  <el-col v-if="tab == 2" :span="24">
                    <profile-photo-uploader />
                  </el-col>
                  <el-col :span="24">
                    <tab-details />
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Password">
              <change-password provider="freelancer" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'
import ProfilePhotoUploader from '~/components/Freelancer/ProfilePhotoUploader.vue'
import ChangePassword from '~/components/ChangePassword'
import TabDetails from './components/TabDetails'
import TabBio from './components/TabBio'
import TabAddress from './components/TabAddress'
import Dashboard from '~/components/Dashboard/Dashboard'

export default {
  components: {
    Dashboard,
    ProfilePhotoUploader,
    ChangePassword,
    TabDetails,
    TabBio,
    TabAddress,
  },

  data () {
    return {
      tab: 0,
      value: 5,
      labelPosition: 'left',
      accountUpdate: {
        title: '',
        intro: '',
        bio: '',
      },
      imageUrl: '/images/profile.jpg',
    }
  },

  computed: {
    ...mapGetters({
      info: 'getFreelancerInfo',
    }),
  },

  watch: {
    tab (newValue) {
      if (newValue === 2) {
        this.$nextTick(() => window.dispatchEvent(new Event('resize')))
      }
      this.dismissAlerts()
    },
  },

  beforeMount () {
    this.$store.dispatch('freelancerGet')
  },

  mounted () {
    if (this.$route.hash === '#nameandemail') {
      this.tab = '2'
    }
  },

  methods: {
    ...mapActions({
      updateBio: 'freelancerPutBio',
    }),

    ...mapMutations(['bioSuccess', 'clearBioError', 'detailSuccess', 'cleanError', 'passwordSuccess', 'clearApiError']),

    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },

    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 2MB!')
      }
      return isJPG && isLt2M
    },

    dismissAlerts () {
      this.clearBioError('')
      this.clearApiError('')
      this.cleanError('')
      this.passwordSuccess(false)
      this.bioSuccess(false)
      this.detailSuccess(false)
    },
  },
}
</script>

<style lang="scss">
.form-control {
  font-size: 14px;
}

</style>
