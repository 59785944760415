<template>
  <div class="detail-form bio">
    <p class="mb-3">Update your address</p>
    <el-form v-if="company" ref="updateBio" :model="company" :rules="rules">
      <p class="mt-4 mb-3 orange-text bold"><i class="el-icon-office-building" /> Address</p>
      <el-form-item label="Address 1" prop="address_1">
        <el-input v-model="company.address_1" maxlength="255" show-word-limit />
      </el-form-item>
      <el-form-item label="Address 2" prop="address_2">
        <el-input v-model="company.address_2" maxlength="255" show-word-limit />
      </el-form-item>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item label="State" prop="state">
            <el-input v-model="company.state" maxlength="100" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="City" prop="city">
            <el-input v-model="company.city" maxlength="100" show-word-limit />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item label="Zip Code" prop="zip">
            <el-input v-model="company.zip" maxlength="15" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="Country" prop="country">
            <el-select v-model="company.country" filterable placeholder="Select" class="w-100">
              <el-option v-for="item in countries" :key="item.code" :label="item.name" :value="item.code" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="text-right buttons pb-4" style="position: relative;">
        <error-alert :errors="Company.errors" />
        <el-button :disabled="Company.saving" :loading="Company.saving" type="success" @click="Company.save()">
          Save
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import ErrorAlert from '~/components/ErrorAlert'
import MyFreelancerProfile from '~/models/MyFreelancerProfile'
import { maxLength, required } from '~/rules'

export default {
  components: { ErrorAlert },

  data () {
    return {
      countries: require('~/data/countries').default,
      Company: new MyFreelancerProfile('address'),
      rules: {
        address_1: [required('Address 1'), maxLength('Address 1')],
        address_2: [required('Address 2'), maxLength('Address 2')],
        country: [required('Country'), maxLength('Country', 100)],
        zip: [required('Zip'), maxLength('Zip', 15)],
        state: [required('State'), maxLength('State')],
        city: [required('City'), maxLength('City')],
      },
    }
  },

  computed: {
    company () {
      return this.Company.data
    },
  },
}
</script>
